import { render, staticRenderFns } from "./v-summary.vue?vue&type=template&id=0ef1b9e2&scoped=true&"
import script from "./v-summary.vue?vue&type=script&lang=js&"
export * from "./v-summary.vue?vue&type=script&lang=js&"
import style0 from "./v-summary.vue?vue&type=style&index=0&id=0ef1b9e2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef1b9e2",
  null
  
)

export default component.exports