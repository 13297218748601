<template>
  <m-modal-empty
    width="550px"
    :full-screen="isViewportMobile"
    @closeModal="$emit('close')"
  >
    <core-box
      :margin="['12 0 0', '10 0 0']"
      :padding="['6 4 0', '4 0 8']"
    >
      <core-box v-if="loading">
        <core-text
          type="heading"
          :align="isViewportDesktop ? 'center' : undefined"
          weight="700"
        >
          Processando...
        </core-text>

        <core-box
          padding="4"
          justify-content="center"
          align-items="center"
        >
          <core-text :align="isViewportDesktop ? 'center' : undefined">
            Em instantes a operação estará concluída.
          </core-text>
          <core-box margin="8">
            <a-point-loader/>
          </core-box>
        </core-box>
      </core-box>
      <core-box v-else>
        <core-text
          type="heading"
          :align="isViewportDesktop ? 'center' : undefined"
          weight="700"
        >
          Selecione o meio de pagamento
        </core-text>
        <ul class="options">
          <li
            @click="openPix()"
          >
            <core-box
              direction="row"
              align-items="center"
              class="item"
              :padding="['8 0', '8 4']"
            >
              <pix-svg class="icon"/>
              <core-box>
                <core-text
                  type="cta"
                  size="small"
                >
                  PIX
                </core-text>
                <core-text
                  color="grey-medium"
                  size="small"
                >
                  Instântaneo, o presente é recebido na hora
                </core-text>
              </core-box>
              <core-box
                direction="row-reverse"
                padding="0 4"
              >
                <core-box
                  direction="row"
                  class="badge"
                  align-items="center"
                  justify-content="space-evenly"
                  padding="1 0"
                >
                  <thunder-svg/>
                  <span>RECOMENDADO</span>
                </core-box>
              </core-box>
              <arrow-right-svg/>
            </core-box>
          </li>
          <li @click="openCreditCard()">
            <core-box
              direction="row"
              align-items="center"
              class="item"
              :padding="['8 0', '8 4']"
            >
              <credit-card-svg class="icon"/>
              <core-box>
                <core-text
                  type="cta"
                  size="small"
                >
                  Cartão de crédito
                </core-text>
                <core-text
                  color="grey-medium"
                  size="small"
                >
                  Parcele o presente em até 6x
                </core-text>
              </core-box>
              <core-box
                direction="row-reverse"
                padding="0 4"
              >
                <payment-options-svg class="payment-options hide-md"/>
              </core-box>
              <arrow-right-svg/>
            </core-box>
          </li>
        </ul>
      </core-box>
    </core-box>
  </m-modal-empty>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CoreBox from '@wedy/legacy-components/core/core-box.vue';
import CoreText from '@wedy/legacy-components/core/core-text.vue';
import MModalEmpty from '@wedy/legacy-components/molecules/m-modal-empty';
import viewportly from '@wedy/legacy-components/mixins/viewportly';

import ArrowRightSvg from '@wedy/legacy-assets/images/icons/arrow-right-outline-black.svg';
import CreditCardSvg from '@wedy/legacy-assets/images/icons/credit-card.svg';
import PaymentOptionsSvg from '@wedy/legacy-assets/images/payment-logos/payment-options.svg';
import PixSvg from '@wedy/legacy-assets/images/wedding-site/checkout/pix.svg';
import ThunderSvg from '@wedy/legacy-assets/images/icons/thunder.svg';
import APointLoader from '@wedy/legacy-components/atoms/a-point-loader.vue';

export default {
  name: 'MPaymentType',
  components: {
    APointLoader,
    ArrowRightSvg,
    CreditCardSvg,
    CoreBox,
    CoreText,
    MModalEmpty,
    PaymentOptionsSvg,
    PixSvg,
    ThunderSvg,
  },
  mixins: [viewportly],
  props: {
    amount: String,
    totalValueWithoutCard: Number,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('checkout', ['checkout']),
    pagarmeKey() {
      return this.checkout.config.crypto_hash;
    },
    interest_rate() {
      return this.checkout.config.interest_rate;
    },
    defaultPaymentConfig() {
      const { gateway } = this.currentInstance.publicContent;

      return {
        customerData: false,
        amount: this.amountInCents(),
        buttonText: 'Presentear',
        maxInstallments: gateway.max_installments,
        defaultInstallment: 1,
        freeInstallments: gateway.free_installments,
        interestRate: this.interest_rate,
        uiColor: this.currentInstance.publicContent.appearance.colors.primary_color,
        createToken: 'false',
        paymentMethods: 'credit_card',
        headerText: 'Total a pagar pelo presente {price_info}',
      };
    },
  },
  mounted() {
    this.trackingCheckoutPaymentStarted();
  },
  methods: {
    ...mapActions({
      submitCheckout: 'checkout/submitCheckout',
      prepareCheckout: 'checkout/prepareCheckout',
    }),
    ...mapMutations({
      addCheckoutData: 'checkout/addCheckoutData',
      markAsError: 'checkout/markAsError',
    }),
    createCheckout() {
      const total = Math.round(Number(this.amount) * 100) / 100;
      const giftsTotal = Math.round(this.totalValueWithoutCard * 100) / 100;
      const cardTotal = Math.round((total - giftsTotal) * 100) / 100;

      const context = this;
      return new window.PagarMeCheckout.Checkout({
        encryption_key: this.pagarmeKey,
        success(data) {
          context.loading = true;
          context.prepareCheckout(data).then(() => {
            context.submitCheckout().then(() => {
              if (context.checkout.hasErrors) {
                context.$gtag.event('Payment errored on checkout mutation (2)');
                context.closePayment();
              } else {
                context.$emit('success');

                context.$gtag.event('Payment successful', {
                  value: total,
                });

                context.$gtag.event('Gift purchase', {
                  value: giftsTotal,
                });

                if (cardTotal > 0) {
                  context.$gtag.event('Card purchase', {
                    value: cardTotal,
                  });
                }
              }
              this.loading = false;
            }, () => {
              context.$gtag.event('Payment errored on submitCheckout');
              context.markAsError();
              context.closePayment();
              context.loading = false;
            });
          });
        },
        error(err) {
          context.$gtag.event('Payment errored on Pagar.me');
          context.markAsError();
          context.closePayment();
          console.error(err);
        },
      });
    },
    amountInCents() {
      const cents = this.amount.replace('.', '').replace(',', '');
      return parseInt(cents, 10);
    },
    openCreditCard() {
      this.$gtag.event('Selected credit card');
      const checkoutInterface = this.createCheckout();
      checkoutInterface.open(this.defaultPaymentConfig);
    },
    openPix() {
      this.$gtag.event('Selected PIX');
      const checkoutInterface = this.createCheckout();
      const pixConfig = { paymentMethods: 'pix' };
      const config = { ...this.defaultPaymentConfig, ...pixConfig };
      checkoutInterface.open(config);
    },
    closePayment() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="stylus">
  .options
    padding 0
    cursor pointer
    li
      padding 0 get_spacing(4)
      list-style none
      &:not(:last-child)
        .item
          border-bottom 1px solid var(--grey-xlight)
      .badge
        max-width 110px
        padding 0px 8px
        border-radius 4px
        font-size 9px
        font-weight 700
        line-height 22px
        color var(--light-color)
        background-color var(--light-gradient-color)
        +for_down(md)
          min-width 90px
          font-size 8px
      &:hover
        background-color var(--grey-xlight)
    svg
      fill var(--dark-color)
      &.icon
        width 40px
        height 30px
        margin-right get_spacing(4)
      &.payment-options
        min-width 50px
</style>
