import Vue from 'vue';

import VueGtag from 'vue-gtag';
import router from '../router';

export default function () {
  Vue.use(VueGtag, {
    config: {
      id: 'G-6T3RM3K5Z6',
    },
  }, router);
}
