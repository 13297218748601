/* eslint-disable no-param-reassign, no-undef */

const TrackingManager = {};

TrackingManager.install = function b(Vue) {
  Vue.mixin({
    methods: {
      trackingCheckoutStarted() {
        // do nothing
      },
      trackingCheckoutPaymentStarted() {
        // do nothing
      },
      trackingCheckoutPaymentDone() {
        // do nothing
      },
      trackingCheckoutIntentionToRemoveCardByCloseSvg() {
        // do nothing
      },
      trackingCheckoutCardRemoveByModal() {
        // do nothing
      },
      trackingCheckoutRemoveCard() {
        // do nothing
      },
      trackingCheckoutAcceptRemoving() {
        // do nothing
      },
      trackingCheckoutGiveUpRemoving() {
        // do nothing
      },
      trackingCheckoutOpenedPickMessageModal() {
        // do nothing
      },
      trackingCheckoutChoseMessageFromModal() {
        // do nothing
      },
    },
  });
};

export default TrackingManager;
