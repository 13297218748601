<template>
  <div class="modal-card__content">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="confirmCardRefuse != 1"
        :key="confirmCardRefuse"
        :class="[
          {'card__simple': currentCardindex == (isSadCardInStart ? 1 : 0)},
          {'card__classic': currentCardindex == (isSadCardInStart ? 2 : 1)},
          {'card__premium': currentCardindex == (isSadCardInStart ? 3 : 2)},
          {'card__empty': currentCardindex == (isSadCardInStart ? 0 : 3)},
        ]"
        class="modal-card"
      >
        <div class="container grid-lg">
          <close-svg
            :class="{'no__card' : currentCardindex === 3}"
            class="modal-card__close"
            @click="closePickCard(false)"
          />
          <div class="columns">
            <div class="column col-xs-12">
              <p class="title">
                Vamos embrulhar o presente?
              </p>
            </div>
          </div>
        </div>
        <div class="carousel-container">
          <carousel-3d
            :perspective="0"
            :space="200"
            :loop="false"
            :start-index="currentCardindex"
            :display="3"
            dir="ltr"
            @before-slide-change="beforeCardChange"
            @after-slide-change="selectedCard"
          >
            <slide
              v-if="isSadCardInStart"
              v-show="currentCardindex != 3"
              :index="0"
            >
              <div class="card fourth">
                <sad-face-svg/>
              </div>
            </slide>
            <slide
              v-show="currentCardindex != 3"
              :index="isSadCardInStart ? 1 : 0"
            >
              <div
                :style="{'background-image':
                  `url(${instanceImages.wedding_site.pick_cards.simple})`}"
                class="card first"
                @dblclick="closePickCard(false)"
              />
            </slide>
            <slide :index="isSadCardInStart ? 2 : 1">
              <div
                :style="{'background-image':
                  `url(${instanceImages.wedding_site.pick_cards.classic})`}"
                class="card second"
                @dblclick="closePickCard(false)"
              />
            </slide>
            <slide
              v-show="currentCardindex != 0"
              :index="isSadCardInStart ? 3 : 2"
            >
              <div
                :style="{'background-image':
                  `url(${instanceImages.wedding_site.pick_cards.premium})`}"
                class="card third"
                @dblclick="closePickCard(false)"
              />
            </slide>
            <slide
              v-if="!isSadCardInStart"
              v-show="currentCardindex != 0"
              :index="3"
            >
              <div class="card fourth">
                <sad-face-svg/>
              </div>
            </slide>
          </carousel-3d>
        </div>
        <div class="container grid-lg">
          <div class="columns card-desc-container">
            <div clascs="column col-xs-12">
              <div class="card-info">
                <p class="card-title">
                  {{ currentCard.name }}
                </p>
                <p class="card-description">
                  {{ currentCard.description }}
                </p>
                <div
                  class="card-price"
                >
                  <!-- eslint-disable-next-line -->
                  <div
                    v-if="discountStatus &&
                      currentCard.type === 'classic' &&
                      Number(currentCard.price) !== 0"
                  >
                    De
                    <span style="text-decoration: line-through;">
                      {{ currentCard.price | toCurrency }}
                    </span>
                    por
                    <span class="discount-aplicaded">
                      {{ 0.7 * currentCard.price | toCurrency }}
                    </span>
                  </div>
                  <div v-else>
                    {{ currentCard.price | toCurrency }}
                  </div>
                </div>
                <a-legacy-button
                  class="card__choose"
                  rounded
                  stacked
                  @click.native="closePickCard(false)"
                >
                  {{ currentCardindex == (isSadCardInStart ? 0 : 3) ?
                    'Não quero enviar o cartão' : 'Escolher cartão!' }}
                </a-legacy-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <m-refuse-modal
        v-else
        :key="confirmCardRefuse"
        :couple-name="coupleName"
        :couple-image="coupleImage"
        @backTCards="goBackToCards"
        @closePickCard="closePickCard(true)"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import ALegacyButton from '@wedy/legacy-components/atoms/a-legacy-button.vue';
import SadFaceSvg from '@wedy/legacy-assets/images/icons/sad-face.svg';
import CloseSvg from '@wedy/legacy-assets/images/icons/close.svg';
import { toCurrency } from '@wedy/legacy-components/utils/masks';
import MRefuseModal from './m-refuse-modal.vue';

export default {
  name: 'OPickCard',
  components: {
    Carousel3d,
    Slide,
    MRefuseModal,
    ALegacyButton,
    SadFaceSvg,
    CloseSvg,
  },
  filters: {
    toCurrency(value) {
      return toCurrency(Number(value || 0));
    },
  },
  props: {
    cards: Array,
    currentCardindex: Number,
    sadCardInStart: Boolean,
    openingStep: {
      type: Number,
      default: 0,
    },
    coupleName: String,
    coupleImage: String,
  },
  data() {
    return {
      confirmCardRefuse: 0,
      isSadCardInStart: false,
    };
  },
  computed: {
    ...mapGetters('checkout', ['discountStatus', 'discountCard']),
    currentCard() {
      return this.cards[this.currentCardindex];
    },
    sadCardIndex() { return this.isSadCardInStart ? 0 : 3; },
  },
  created() {
    this.confirmCardRefuse = this.openingStep;
    this.isSadCardInStart = this.sadCardInStart;
  },
  methods: {
    selectedCard(index) {
      this.$emit('selectedCard', index);
    },
    beforeCardChange() {
      const lastElement = document.getElementsByClassName('left-1')['0'];
      lastElement.style.display = 'block';
    },
    goBackToCards() {
      this.$gtag.event('Having second thoughts...');
      this.confirmCardRefuse += 1;
      const defaultCard = this.isSadCardInStart ? 2 : 1;
      this.$emit('selectedCard', defaultCard);
    },
    closePickCard(removeCard) {
      if (this.currentCard.type === '' && this.confirmCardRefuse === 0) {
        this.confirmCardRefuse += 1;
        this.$gtag.event('Removing card');
      } else {
        if (removeCard) {
          this.trackingCheckoutCardRemoveByModal();
          this.selectedCard(this.sadCardIndex);
          this.$gtag.event('Removed card');
        } else {
          this.$gtag.event(this.currentCardindex === (this.isSadCardInStart ? 0 : 3) ? 'Removed card' : 'Picked card');
        }
        this.$emit('closePickCard');
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
* {
  transition: .3s;
}

.modal-card__content {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#171717, .95);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 20;

  .modal-card {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 20;
    padding-top: 2rem;
    padding-bottom: 2rem;
    top: 0;
    left: 0;
    background: #ffffff;

    @media (min-width: md) {
      position: relative;
      width: 900px;
      max-height: 600px;
      border-radius: 10px;
    }

    @media (max-width: md) {
      height: 100%;
      overflow: scroll;
    }

    .modal-card__close {
      position: absolute;
      fill: #ffffff;
      right: 1.5rem;
      top: 1.5rem;
      width: 16px;
      cursor: pointer;
      z-index: 50;

      &:hover {
        opacity: .7;
      }

      &.no__card {
        fill: #aaa;
      }
    }

    .carousel-container {
      width: 100%;
      height: auto;
    }

    .title {
      color: #ffffff;
      text-align: center;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 0.92;

      @media (min-width: md) {
        margin: 0 0 1rem 0;
      }
      @media (max-width: md) {
        margin: 5rem 0 1rem 0;
      }
    }

    .card {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      line-height: 1.33;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: md) {
        cursor: pointer;
      }

      &.first {
        background-size: cover;
      }

      &.second {
        background-size: cover;
      }

      &.third {
        background-size: cover;
      }

      &.fourth {
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.31);
        color: var(--grey-medium);
        text-align: center;
      }
    }

    .card-desc-container {
      justify-content: center;

      .card-info {
        transition: all .3s;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 320px;
        margin-left: auto;
        margin-right: auto;

        .card-title {
          margin: 0;
          margin-top: 1rem;
          color: #ffffff;
          font-size: 1.25rem;
          font-weight: 700;
          text-align: center;
        }

        .card-description {
          font-size: .875rem;
          color: #ffffff;
          text-align: center;
          line-height: 1.14;
        }

        .card-price {
          color: #ffffff;
          font-size: 1rem;
          font-weight: 600;
          text-align: center;
        }

        .card__choose {
          background: #ffffff;
          font-weight: 600;
          margin-top: 1.4rem;
          padding: 1rem;
        }
      }
    }
  }
}

.card__simple {
  background-image: radial-gradient(#4A77C6 20%, #1E345A 90%) !important;

  .card__choose {
    color: #33548e;
  }
}

.card__classic {
  background-image: radial-gradient(#FF937C 20%, #C48679 90%) !important;

  .card__choose {
   color: #ee8f7b;
  }
}

.card__premium {
  background-image: radial-gradient(#E6B176 20%, #73593B 90%) !important;

  .card__choose {
    color: #9a774f
  }
}

.card__empty {
  background-image: radial-gradient(#fff 20%, #ededed 90%) !important;

  .card-title {
    color: #aaa !important;
  }

  .card-price {
    color: #aaa !important;
  }

  .title {
    color: #aaaaaa !important;
  }

  .card-description {
    color: var(--grey-medium) !important;
  }

  .card__choose {
    background: #aaaaaa !important;
    color: #ffffff !important;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
<style lang="stylus">
card_width = 186px;
card_height = 284px;

.carousel-3d-container {
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
}

.carousel-3d-slider {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: card_width !important;
  height: card_height !important;
  margin: 20px auto !important;
}

.carousel-3d-slide {
  width: card_width !important;
  height: card_height !important;
  border: none !important;
  background: none !important;
  box-shadow: rgba(0, 0, 0, 0.31) 0px 0px 15px 0px;
  border-radius: 5px;
}

.card-price {
  font-size: .8rem;

  & .discount-aplicaded {
    background: rgb(68,204,146);
    padding: 3px 5px;
    font-weight: bolder;
    border-radius: .35rem;
    font-size: 1rem;
  }
}

</style>
